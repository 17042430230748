import React, { useCallback } from 'react';
import { Color } from 'interfaces';
import {
  AggregatedProfileProperties,
  AggregatedProfileType,
  AggregatedSuperRequirementProperties,
  ComposedOrderProperties,
  Feature,
  Order,
  OrderDetailDefaultGroupBy,
  OrderDetailDefaultSortBy,
  OrderForm,
  OrderListMode,
  OrderPatientMetaPropertiesSchema,
  OrderRequirement,
  OrderRequirementSchema,
  OrderSample,
  OrderWizardLocalization,
  OrderWizardRequirement,
  RequirementType,
  WriteableOrderProperties,
  WriteableOrderPropertiesSchema,
} from 'interfaces/api';
import { arrayify } from 'utils/helpers';
import { BadgeType, Container, IconProps, List, ListItem } from 'components';
import { Translate, useIntlStoreSelectors } from 'providers';
import messages from 'messages';
import { faClipboardMedical, faCommentAlt, faCommentAltCheck, faCommentAltExclamation, faFileCheck, faVial as faVialLight } from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle, faUserCircle } from '@fortawesome/pro-solid-svg-icons';
import { faSyringe, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { useGuard } from 'containers';
import { OrdersListContext } from 'modules/orders/index';
import { filter, find, flatten, get, groupBy, isEmpty, isNull, keys, map, mapValues, omitBy, pick, reduce, set, sortBy, sum, sumBy, uniqBy, values } from 'lodash';
import { freeTextByType, getBadgeForRequirement, isProfile, isSuperRequirement, useInvoiceTo } from 'modules/orders/containers/OrderWizard/utils';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { FormatPrice } from 'providers/IntlProvider/FormatPrice';
import { useOfficeDoctorContext, useOrdersContext } from 'modules/orders/providers';
import { OrderStatusColor } from 'modules/orders/constants.tsx';
import { useEnv } from 'providers/EnvProvider';

export const getListItemFlag = (entity: ComposedOrderProperties | Order) => {
  return flatten(arrayify(entity.status).map(s => OrderStatusColor[s]));
};

export const isComposedOrder = (entity: ComposedOrderProperties | Order): entity is ComposedOrderProperties => {
  return (entity as ComposedOrderProperties)?.orderCount !== undefined;
};

export const getOrderTransportationSummary = (orders: ListItem[]) => {
  const samples = mapValues(groupBy(flatten(orders.map(o => o.meta.materials as OrderSample[])), 'name'), s => sumBy(s, 'count'));
  const total = sum(values(samples));
  return { samples, total };
};

export const mapCostUnit = (costUnit: string) => ({
  P: 'Privat',
  PRIVAT: 'Privat',
  K: 'Kasse',
  KASSA: 'Kasse',
  KASSE: 'Kasse',
  I: 'Intern',
  INTERN: 'Intern',
})[costUnit?.toUpperCase()] || costUnit;

export const getPatientTitle = (title: string) => !isEmpty(title)
  ? title
  : (
    <span className={'empty-patient-name'}>
      <Translate message={messages.general.noPatientSelected}/>
    </span>
  );

export const getOrderDoctorDisplayName = (entity: ComposedOrderProperties | Order, showRoomNumber: boolean) => {
  let displayString = entity.selectedDoctor || (entity.doctor && entity.doctor.displayName);
  const composedOrder = isComposedOrder(entity) && !isEmpty(entity.hospitalRoomNumber);
  const noComposedOrder = !isComposedOrder(entity) && !isEmpty(entity.patient?.hospitalRoomNumber);
  const isKis = entity.doctor && entity?.doctor.localisation === OrderWizardLocalization.KIS || entity.doctor?.laboratoryLocalisation === OrderWizardLocalization.KIS;
  if (showRoomNumber && isKis) {
    if (noComposedOrder) {
      displayString = createStationAndRoomNumberDisplayString(entity.hospitalStation, entity.patient.hospitalRoomNumber);
    } else if (composedOrder) {
      displayString = createStationAndRoomNumberDisplayString(entity.hospitalStation, entity.hospitalRoomNumber);
    }
  } else if (showRoomNumber) {
    if (noComposedOrder) {
      displayString = createStationAndRoomNumberDisplayString(displayString, entity.patient.hospitalRoomNumber);
    } else if (composedOrder) {
      displayString = createStationAndRoomNumberDisplayString(displayString, entity.hospitalRoomNumber);
    }
  }
  return displayString;
};

const createStationAndRoomNumberDisplayString = (station: string, roomNumber: string): string => {
  return [station, roomNumber].filter(a => a && a.trim().length > 0).join(', ');
};

export const getCurrencyAsciiSign = (localization?: OrderWizardLocalization) => {
  if (localization === OrderWizardLocalization.CHE) {
    return <>CHF&nbsp;</>;
  }
  return <>&euro;</>;
};

const getRequirementBasePrice = (requirement: OrderRequirement, form?: OrderForm) => parseFloat(
  (find(requirement.formLevelPrices, p => p.formId === (form?.id || requirement.formId))?.price || requirement.price) + '',
);

export const getRequirementPrice = (requirement: OrderRequirement, form?: OrderForm, localization?: OrderWizardLocalization) => {

  if (form?.isPrivate && requirement.isCharged) {
    const price = getRequirementBasePrice(requirement, form);
    if (price > 0) {
      return <>{getCurrencyAsciiSign(localization)}{<FormatPrice price={price}/>}</>;
    } else {
      return <Translate message={messages.orders.wizard.priceOnApplication}/>;
    }
  }

  return null;
};

const getRequirementCostsWithFormFactor = (requirements: OrderRequirement[]) => {
  return sumBy(requirements, requirement => getRequirementBasePrice(requirement) * (parseFloat((requirement.form?.priceFactor || '1') + '') || 1));
};

export const useGetTotalPriceLabel = () => {

  const translate = useIntlStoreSelectors.translate();
  const { currentOrder } = useOrdersContext();
  const { wizardSettings } = useOfficeDoctorContext();
  const { getSelectedLabel } = useInvoiceTo();

  return useCallback((requirements: OrderRequirement[], localization?: OrderWizardLocalization) => {

    const privateRequirements = requirements.filter(r => find(wizardSettings?.forms, { id: r.formId })?.isPrivate && r.isCharged && !r.cancelled_at);

    if (!privateRequirements.length) {
      return null;
    }

    const formBaseCosts = sumBy(uniqBy(map(privateRequirements, r => r.form), 'id'), f => parseFloat((f?.basePrice || '0') + ''));
    const formRequirementCosts = getRequirementCostsWithFormFactor(privateRequirements);
    const totalCosts = formRequirementCosts + formBaseCosts;

    const hasMicrobiological = requirements.filter(requirement => requirement.entityType === RequirementType.Microbiological).length > 0;
    const hasUndefinedCosts = privateRequirements.filter(requirement => !(getRequirementBasePrice(requirement) > 0)).length > 0;

    const groupedByInvoiceTo = groupBy(privateRequirements, r => r.invoiceTo || r.form?.defaultInvoiceTo || currentOrder?.patient?.invoiceTo);

    return (
      <>
        <Container className={'total-price'}>
          <span className={'total-price-label'} dangerouslySetInnerHTML={{ __html: translate(messages.orders.wizard.costs) }}/>
          {hasMicrobiological || hasUndefinedCosts
            ? <Translate message={messages.orders.wizard.priceOnApplication}/>
            : <>{getCurrencyAsciiSign(localization)}<FormatPrice price={totalCosts}/></>
          }
        </Container>
        {wizardSettings?.preferences?.orderWizardSeparateInvoiceTo && (
          <>
            {map(groupedByInvoiceTo, (requirements, invoiceTo) => (
              <Container className={'total-price total-price-sub'}>
                <span className={'total-price-label'}>
                  {getSelectedLabel(invoiceTo)}
                </span>
                <>{getCurrencyAsciiSign(localization)}<FormatPrice price={getRequirementCostsWithFormFactor(requirements)}/></>
              </Container>
            ))}
            <Container className={'total-price total-price-sub'}>
              <span className={'total-price-label'}>
                <Translate message={messages.orders.wizard.costSeperationForm}/>
              </span>
              <>{getCurrencyAsciiSign(localization)}<FormatPrice price={formBaseCosts}/></>
            </Container>
          </>
        )}
      </>
    );
  }, [translate, wizardSettings]);

};

const OrderDetailDefaultGroupByMapping: Record<OrderDetailDefaultGroupBy, string> = {
  [OrderDetailDefaultGroupBy.Forms]: 'form.name',
  [OrderDetailDefaultGroupBy.Materials]: 'materials[0].name',
};

export const useOrderListRequirements = () => {

  const endpoint = useEnv.endpoint();

  const translate = useIntlStoreSelectors.translate();
  const lid = useAuthStoreSelectors.lid();
  const guard = useGuard();

  return useCallback((
    order: Order,
    groupBy: OrderDetailDefaultGroupBy = OrderDetailDefaultGroupBy.Forms,
    sort: OrderDetailDefaultSortBy = OrderDetailDefaultSortBy.Order,
  ): ListItem<OrderRequirement>[] => {

    const items = order.requirements?.reduce((items, requirement) => {

      const { id, longName, shortName, leftRight, origin, analyses, selectedAnalyses } = requirement;

      const item: ListItem = { id };

      item.images = filter((requirement.materials || []).map((material) => {
        return `${endpoint}/api/orders/samples/${material.sampleId}/image/small?lid=${lid}`;
      }));

      item.title = longName;
      if (requirement.entityType === RequirementType.Microbiological) {
        item.title += ' (' + filter([
          shortName,
          !!leftRight && translate(messages.orders.requirementOptions.leftRight.options[leftRight]),
          origin,
        ]).join(', ') + ')';
      }

      item.icons = [];

      if (requirement.ruleInfoText) {
        item.icons.push(faFileCheck);
      }

      if (requirement.hint) {
        item.icons.push(faClipboardMedical);
      }

      if (requirement.reRequested) {
        item.icons.push({
          icon: [{ icon: faVialLight }, { icon: faPlusCircle, transform: 'shrink-7 down-4 right-3.5' }],
          tooltip: messages.orders.wizard.realReRequest.tooltip,
        });
      }

      if (requirement.cancelled_at) {
        item.icons.push({
          icon: faTimesCircle, color: Color.Red,
          tooltip: messages.orders.controls.cancelRequirement.isCancelled,
        });
      }

      guard({ feature: Feature.RequirementFreeText }, () => {
        if (requirement.freeTextAllowed) {
          item.icons.push(freeTextByType(requirement) ? faCommentAltCheck : (requirement.freeTextMandatory ? { icon: faCommentAltExclamation, color: Color.Red } : faCommentAlt));
        }
      });

      item.subtitle = requirement.entityType === RequirementType.Clinical ? shortName : selectedAnalyses.map(a => find(analyses, { shortName: a })?.longName).join(', ');

      if (requirement.selectedLocalizations?.length > 0) {
        item.subtitle += ' - ' + requirement.selectedLocalizations.map(l => l.name1).join(', ');
      }

      if (requirement.form.isDynamicMaterial && requirement.dynamicMaterials?.length > 0) {
        item.body = (
          <List
            className={'dynamic-materials'}
            items={requirement.dynamicMaterials.map((material, idx) => ({ id: idx, title: `#${idx + 1} ${material.text}` }))}
          />
        );
      }

      item.faded = !!requirement.cancelled_at;
      item.meta = requirement;

      item.badge = getBadgeForRequirement(requirement);
      item.badgeType = BadgeType.Inline;

      item.groupByValue = get(requirement, OrderDetailDefaultGroupByMapping[groupBy]);

      if (groupBy === OrderDetailDefaultGroupBy.Materials) {
        return [...items, ...requirement.materials.map(m => ({ ...item, groupByValue: m.name }))];
      }

      return [...items, item];

    }, [] as ListItem[]);

    return sortBy(items, (item, idx) => sort === OrderDetailDefaultSortBy.Name ? item.groupByValue + '-' + item.title : idx);

  }, []);

};

export const getWriteableOrder = (order: WriteableOrderProperties, filterRequirements?: boolean) => omitBy({
  ...pick(omitBy(order, isNull), keys(WriteableOrderPropertiesSchema)),
  patient: pick(omitBy(order.patient, isNull), keys(OrderPatientMetaPropertiesSchema)),
  covid: pick(omitBy(order.covid, isNull), keys()),
  requirements: (order.requirements || [])
    .map(r => pick(omitBy(r, isNull), keys(OrderRequirementSchema)))
    .filter(r => !filterRequirements || r.profileId || r.form?.costUnit === order.costUnit),
}, isNull);

export const getRequirementId = (requirement: OrderWizardRequirement | AggregatedProfileProperties | AggregatedSuperRequirementProperties) => {
  return requirement.id + `:${JSON.stringify(requirement.flags)}:${JSON.stringify(requirement.filter)}:${JSON.stringify((requirement as any).selectedAnalyses)}`;
};

export const getRequirementShortName = (requirement: OrderWizardRequirement | AggregatedProfileProperties) => {
  return isProfile(requirement) || isSuperRequirement(requirement)
    ? requirement.shortName
    : requirement.shortName + (requirement.laboratoryGroup !== 'LA' ? ` (${requirement.laboratoryGroup})` : '');

};

export const SampleInLaboratoryIcon: IconProps = {
  icon: [{ icon: faSyringe }, { icon: faUserCircle, transform: 'shrink-7 down-4 right-3.5' }],
  tooltip: messages.orders.filters.sampleInLabFilter.label,
};

export const isSingleOrderView = (context: OrdersListContext) => {
  return context.mode !== OrderListMode.Bookmarked;
};

export const sortOrders = <T extends Order | WriteableOrderProperties>(orders: T[], sortByName: boolean) => sortBy(orders.map((order, idx) => {

  const { displayName, lastName, firstName } = order.patient || {};

  const sort = sortByName
    ? displayName || (lastName?.toLowerCase() + ' ' + firstName?.toLowerCase())
    : idx;

  return { order, idx, sort };

}), 'sort');

/**
 *
 */
export const usePatientInvoiceToOptions = (hideInsurance: boolean) => {
  return filter([
    { value: 'Auftraggeber', label: messages.orders.additionalFields.invoiceToOptions.purchaser },
    { value: 'Patient', label: messages.orders.additionalFields.invoiceToOptions.patient },
    !hideInsurance ? { value: 'Versicherung', label: messages.orders.additionalFields.invoiceToOptions.insurance } : undefined,
    { value: 'Andere', label: messages.orders.additionalFields.invoiceToOptions.other },
  ]);
};

export type ProfileGroups = Record<string, {
  name: string;
  profiles: [{
    id: number;
    name: string;
    color: string;
    costUnit: string;
    diagnosis: string;
    freeText: string;
    orderReason: string;
    selectedDiagnoses: Record<string, string>;
    type: AggregatedProfileType;
  }];
}>;

export const groupProfilesByCostUnit = (profiles: AggregatedProfileProperties[], hasRequirementDiagnosesFeature: boolean, noCostUnitLabel: string) => reduce(profiles, (acc, p) => {
  const costUnit = p.costUnit || noCostUnitLabel;

  if (!get(acc, costUnit)) {
    set(acc, costUnit, { name: costUnit, profiles: [] });
  }

  acc[costUnit].profiles.push({
    costUnit,
    id: p.entityId,
    name: p.name,
    color: p.color,
    diagnosis: p.diagnosis,
    freeText: p.freeText,
    orderReason: p.orderReason,
    selectedDiagnoses: hasRequirementDiagnosesFeature ? p.selectedDiagnoses : undefined,
    type: p.entityType,
  });

  return acc;
}, {} as ProfileGroups);
